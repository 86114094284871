import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Layout from '../components/Layout'
import { H1 } from '../components/Titles'
import Planes from '../containers/PlaneCarousel'
import { Card } from '../components/Card'
export default () => (
  <Layout>
    <H1>Technical Specifications</H1>
    <Card>
    <ul>
      <li>
        No altitude limitations – up to 30.000ft or 10.000m, allowing guaranteed
        flying in all weather conditions.
      </li>
      <li>
        Dual engine – turbo prop with max speed around 550km/hr, ideal for long
        distance cruising and minimum speed 180km/hr ideal for relay
        applications.
      </li>
      <li>
        Up to Seven hour range in the air, depending from weather conditions.
        Specially trained team of pilots
      </li>
      <li>
        Specially designed and adapted cargo pod, allowing 8 GPS controlled
        tracking antennas.
      </li>
      <li>
        Specially designed transmission arm, ranging 3m below the aircraft
      </li>
      <li> Specially developped and certified equipment racks. </li>
      <li> All equipment and adaptations are EASA certified</li>
    </ul>
</Card>
    <div
      css={css`
        height: 20rem;
      `}
    >
      <Planes />
    </div>
  </Layout>
)
